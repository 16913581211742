// - Import external components
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
// require("history").createBrowserHistory

import createSagaMiddleware, { END } from 'redux-saga';
// import {createLogger} from 'redux-logger'
import { rootReducer } from './reducers';
import { fromJS } from 'immutable';

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();
// export const history = createHistory();

// Logger option for transforming immutable js
// const logger = createLogger({
//     stateTransformer: (state) => {
//         return state.toJS()
//     }
// })

const sagaMiddleware = createSagaMiddleware();
// - initial state
let initialState = {};

// - Config and create store of redux
const composeEnhancers = composeWithDevTools({
  // Specify extension’s options like fullname, actionsBlacklist, actionsCreators, serialize...
});

// let store = createStore(rootReducer, fromJS(initialState), composeEnhancers(
//     applyMiddleware(logger, thunk, routerMiddleware(history))
// ))

// reomve logger
let store = createStore(
  rootReducer,
  fromJS(initialState),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);

export default { store, history };
