import { Map } from 'immutable';
import { EligibilityReducerState } from './EligibilityReducerState';
// import { IEligibilityReducerAction } from './IEligibilityReducerAction';
import { eligibilityActionType } from '../../../constants/eligibilityActionType';

const eligibilityReducer = (
  state = Map(new EligibilityReducerState()),
  action
) => {
  const { payload } = action;

  switch (action.type) {
    case eligibilityActionType.CLEAR_ALL_DATA_USER:
      return Map(new EligibilityReducerState());
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_DATA:
      return state.setIn([payload.cardAttribute], payload.cardAttributeValue);
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_BASIC_INFO:
      return state.setIn(
        ['basicInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_INCOME_INFO_SALARIED:
      return state.setIn(
        ['incomeInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_INCOME_INFO_PROFESSIONAL:
      return state.setIn(
        ['professionalInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_INCOME_INFO_PROFESSIONAL_COAPPLICANT:
      return state.setIn(
        ['professionalInfoCoapplicant', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_INCOME_INFO_BUSINESSMAN:
      return state.setIn(
        ['businessIncomeInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_INCOME_INFO_LANDLOARD:
      if (payload.cardAttribute === 'haveHouseTypes') {
        return state.setIn(
          ['haveHouseTypes', payload.cardAttributeValue.key],
          payload.cardAttributeValue.value
        );
      } else if (payload.cardAttribute === 'coApplicantHaveHouseTypes') {
        return state.setIn(
          ['coApplicantHaveHouseTypes', payload.cardAttributeValue.key],
          payload.cardAttributeValue.value
        );
      } else {
        return state.setIn(
          [
            'landLoardIncomeInfo',
            payload.cardAttributeValue.key,
            payload.cardAttribute,
          ],
          payload.cardAttributeValue.value
        );
      }
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_CAR_LOAN_INFO:
      return state.setIn(
        ['carLoanInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_PERSONAL_LOAN_INFO:
      return state.setIn(
        ['personalLoanInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_HOME_LOAN_INFO:
      return state.setIn(
        ['homeLoanInfo', payload.cardAttribute],
        payload.cardAttributeValue
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_EXISTING_LOAN_INFO:
      return state.setIn(
        [
          'existingLoanInfo',
          payload.cardAttributeValue.index,
          payload.cardAttribute,
        ],
        payload.cardAttributeValue.value
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_EXISTING_CARD_INFO:
      return state.setIn(
        [
          'existingCardInfo',
          payload.cardAttributeValue.index,
          payload.cardAttribute,
        ],
        payload.cardAttributeValue.value
      );
    case eligibilityActionType.SAVE_ELIGIBILITY_CARD_COAPPLICANT_INFO:
      if (payload.cardAttributeValue.attribute.length > 0) {
        if (
          payload.cardAttributeValue.attribute === 'existingLoanInfo' ||
          payload.cardAttributeValue.attribute === 'existingCardInfo'
        ) {
          return state.setIn(
            [
              'coApplicantInfo',
              payload.cardAttributeValue.attribute,
              payload.cardAttributeValue.index,
              payload.cardAttribute,
            ],
            payload.cardAttributeValue.value
          );
        } else if (
          payload.cardAttributeValue.attribute === 'landLoardIncomeInfo'
        ) {
          return state.setIn(
            [
              'coApplicantInfo',
              payload.cardAttributeValue.attribute,
              payload.cardAttributeValue.key,
              payload.cardAttribute,
            ],
            payload.cardAttributeValue.value
          );
        } else {
          return state.setIn(
            [
              'coApplicantInfo',
              payload.cardAttributeValue.attribute,
              payload.cardAttribute,
            ],
            payload.cardAttributeValue.value
          );
        }
      } else {
        return state.setIn(
          ['coApplicantInfo', payload.cardAttribute],
          payload.cardAttributeValue.value
        );
      }
    default:
      return state;
  }
};

export default eligibilityReducer;
