import { Map, fromJS, List } from 'immutable';

class CoApplicantState {
  basicInfo = Map({});
  incomeInfo = Map({});
  businessIncomeInfo = Map({});
  landLoardIncomeInfo = Map({});
  existingCardInfo = Map({});
  existingLoanInfo = Map({});
}

export class EligibilityReducerState {
  basicInfo = Map({});
  incomeInfo = Map({});
  professionalInfo = Map({});
  professionalInfoCoapplicant = Map({});
  businessIncomeInfo = Map({});
  haveHouseTypes = Map({});
  coApplicantHaveHouseTypes = Map({});
  landLoardIncomeInfo = Map({});
  carLoanInfo = Map({});
  homeLoanInfo = Map({});
  personalLoanInfo = Map({});
  existingCardInfo = Map({});
  existingLoanInfo = Map({});
  coApplicantInfo = Map(new CoApplicantState());
}
