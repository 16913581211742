export const eligibilityActionType = {
  CLEAR_ALL_DATA_USER: 'CLEAR_ALL_DATA_USER',
  SAVE_ELIGIBILITY_CARD_DATA: 'SAVE_ELIGIBILITY_CARD_DATA',
  SAVE_ELIGIBILITY_CARD_BASIC_INFO: 'SAVE_ELIGIBILITY_CARD_BASIC_INFO',
  SAVE_ELIGIBILITY_CARD_INCOME_INFO_SALARIED:
    'SAVE_ELIGIBILITY_CARD_INCOME_INFO_SALARIED',
  SAVE_ELIGIBILITY_CARD_INCOME_INFO_PROFESSIONAL:
    'SAVE_ELIGIBILITY_CARD_INCOME_INFO_PROFESSIONAL',
  SAVE_ELIGIBILITY_CARD_INCOME_INFO_BUSINESSMAN:
    'SAVE_ELIGIBILITY_CARD_INCOME_INFO_BUSINESSMAN',
  SAVE_ELIGIBILITY_CARD_INCOME_INFO_LANDLOARD:
    'SAVE_ELIGIBILITY_CARD_INCOME_INFO_LANDLOARD',
  SAVE_ELIGIBILITY_CARD_CAR_LOAN_INFO: 'SAVE_ELIGIBILITY_CARD_CAR_LOAN_INFO',
  SAVE_ELIGIBILITY_CARD_PERSONAL_LOAN_INFO:
    'SAVE_ELIGIBILITY_CARD_PERSONAL_LOAN_INFO',
  SAVE_ELIGIBILITY_CARD_HOME_LOAN_INFO: 'SAVE_ELIGIBILITY_CARD_HOME_LOAN_INFO',
  SAVE_ELIGIBILITY_CARD_EXISTING_LOAN_INFO:
    'SAVE_ELIGIBILITY_CARD_EXISTING_LOAN_INFO',
  SAVE_ELIGIBILITY_CARD_EXISTING_CARD_INFO:
    'SAVE_ELIGIBILITY_CARD_EXISTING_CARD_INFO',
  SAVE_ELIGIBILITY_CARD_COAPPLICANT_INFO:
    'SAVE_ELIGIBILITY_CARD_COAPPLICANT_INFO',
  SAVE_ELIGIBILITY_CARD_INCOME_INFO_PROFESSIONAL_COAPPLICANT:
    'SAVE_ELIGIBILITY_CARD_INCOME_INFO_PROFESSIONAL_COAPPLICANT',
};
