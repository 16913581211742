// - Import react ./components
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import MasterLoadingComponent from '../components/masterLoading/MasterLoadingComponent';
import PublicRoute from './PublicRoute';

const AsyncHome = Loadable({
  loader: () => import('../containers/home'),
  loading: MasterLoadingComponent,
});

const AsyncAbout = Loadable({
  loader: () => import('../containers/About'),
  loading: MasterLoadingComponent,
});

const AsyncCreditCardEligibility = Loadable({
  loader: () => import('../containers/Eligibility/Eligibility'),
  loading: MasterLoadingComponent,
});

const AsyncPersonalEligibility = Loadable({
  loader: () => import('../containers/Eligibility/Eligibility'),
  loading: MasterLoadingComponent,
});

const AsyncHomeEligibility = Loadable({
  loader: () => import('../containers/Eligibility/Eligibility'),
  loading: MasterLoadingComponent,
});

const AsyncCarEligibility = Loadable({
  loader: () => import('../containers/Eligibility/Eligibility'),
  loading: MasterLoadingComponent,
});

const AsyncCreditCardCompare = Loadable({
  loader: () => import('../containers/CreditCardCompare'),
  loading: MasterLoadingComponent,
});

const AsyncCreditCardDetails = Loadable({
  loader: () => import('../containers/CreditCardDetails'),
  loading: MasterLoadingComponent,
});

const AsyncPersonalLoan = Loadable({
  loader: () => import('../containers/PersonalLoan'),
  loading: MasterLoadingComponent,
});

const AsyncCarLoan = Loadable({
  loader: () => import('../containers/CarLoan'),
  loading: MasterLoadingComponent,
});

const AsyncHomeLoan = Loadable({
  loader: () => import('../containers/HomeLoanDetails'),
  loading: MasterLoadingComponent,
});

const AsyncEligibilitySuccess = Loadable({
  loader: () => import('../containers/SuccesInfo'),
  loading: MasterLoadingComponent,
});

const AsyncnewsPage = Loadable({
  loader: () => import('../containers/newsPage'),
  loading: MasterLoadingComponent,
});

const AsynscbSorry1 = Loadable({
  loader: () => import('../containers/Sorry1'),
  loading: MasterLoadingComponent,
});

const AsynscTermsCondition = Loadable({
  loader: () => import('../containers/TermsCondition'),
  loading: MasterLoadingComponent,
});

const AsynscPrivacyPolicy = Loadable({
  loader: () => import('../containers/PrivacyPolicy'),
  loading: MasterLoadingComponent,
});

const AsynscContact = Loadable({
  loader: () => import('../containers/Contact'),
  loading: MasterLoadingComponent,
});

const AsynscDps = Loadable({
  loader: () => import('../containers/Dps'),
  loading: MasterLoadingComponent,
});

const AsynscDisclaimer = Loadable({
  loader: () => import('../containers/Disclaimer'),
  loading: MasterLoadingComponent,
});

const AsynscTripleMoney = Loadable({
  loader: () => import('../containers/TripleMoney'),
  loading: MasterLoadingComponent,
});

const AsynscDoubleMoney = Loadable({
  loader: () => import('../containers/DoubleMoney'),
  loading: MasterLoadingComponent,
});

const AsynscSavingAccount = Loadable({
  loader: () => import('../containers/SavingAccount'),
  loading: MasterLoadingComponent,
});

const AsynscComplainsGuide = Loadable({
  loader: () => import('../containers/ComplainsGuide'),
  loading: MasterLoadingComponent,
});

const AsynscPersonalLoan = Loadable({
  loader: () => import('../containers/PersonalLoanDetails'),
  loading: MasterLoadingComponent,
});

const AsynscFixedDeposit = Loadable({
  loader: () => import('../containers/FixedDeposit'),
  loading: MasterLoadingComponent,
});

const AsynscChooseTenure = Loadable({
  loader: () => import('../containers/ChooseTenure'),
  loading: MasterLoadingComponent,
});

const AsynscMonthlyBenifit = Loadable({
  loader: () => import('../containers/MonthlyBenifit'),
  loading: MasterLoadingComponent,
});

const AsynscSignIn = Loadable({
  loader: () => import('../containers/SignIn'),
  loading: MasterLoadingComponent,
});

const AsynscSignUp = Loadable({
  loader: () => import('../containers/SignUp'),
  loading: MasterLoadingComponent,
});

const AsynscTrack = Loadable({
  loader: () => import('../containers/TrackApplication'),
  loading: MasterLoadingComponent,
});

const AsynscAdvertisement = Loadable({
  loader: () => import('../containers/Advertisement'),
  loading: MasterLoadingComponent,
});

const AsynscHomeLoanEMI = Loadable({
  loader: () => import('../containers/HomeLoanEMI'),
  loading: MasterLoadingComponent,
});

const AsynscProfileDetails = Loadable({
  loader: () => import('../containers/ProfileDetails'),
  loading: MasterLoadingComponent,
});

const AsynscApplicationHistory = Loadable({
  loader: () => import('../containers/ApplicationHistory'),
  loading: MasterLoadingComponent,
});

const AsynscActiveApplication = Loadable({
  loader: () => import('../containers/ActiveApplication'),
  loading: MasterLoadingComponent,
});

const AsynscTrackApplicationDetails = Loadable({
  loader: () =>
    import(
      '../components/TrackApplication/ApplicationDetails/ApplicationDetailsComponent'
    ),
  loading: MasterLoadingComponent,
});

const AsynscNodata = Loadable({
  loader: () => import('../components/Nodatafound/NodatafoundComponent'),
  loading: MasterLoadingComponent,
});

const AsynscNodataOffer = Loadable({
  loader: () => import('../components/MobileComponents/OfferComponent'),
  loading: MasterLoadingComponent,
});

const AsynscPartner = Loadable({
  loader: () => import('../containers/Partners/Partners'),
  loading: MasterLoadingComponent,
});

const AsynscSingleNews = Loadable({
  loader: () => import('../containers/SingleNews/singleNews'),
  loading: MasterLoadingComponent,
});

const AsynscMymatches = Loadable({
  loader: () => import('../components/MyMatches/MyMatchesComponent'),
  loading: MasterLoadingComponent,
});

const AsynscPasswordReset = Loadable({
  loader: () => import('../containers/PasswordReset'),
  loading: MasterLoadingComponent,
});

const AsynscAccountSettings = Loadable({
  loader: () => import('../containers/AccountSettingsContainer'),
  loading: MasterLoadingComponent,
});

const AsynscAccountInvestors = Loadable({
  loader: () => import('../containers/Investors'),
  loading: MasterLoadingComponent,
});

const AsynscAccountRequiredDocument = Loadable({
  loader: () => import('../containers/RequiredDocuments'),
  loading: MasterLoadingComponent,
});

const AsynscAccountApplyNow = Loadable({
  loader: () => import('../containers/ApplyNowContainer'),
  loading: MasterLoadingComponent,
});

const AsynscAccountCreditCardCompareInitial = Loadable({
  loader: () => import('../containers/CreditCardCompareInitial'),
  loading: MasterLoadingComponent,
});

const AsyncFdApplicationDetails = Loadable({
  loader: () => import('../containers/OnlineApply/ApplicationDetails'),
  loading: MasterLoadingComponent,
});

const AsyncFeaturePage = Loadable({
  loader: () => import('../containers/FeaturePage'),
  loading: MasterLoadingComponent,
});

const AsyncOnlineApply = Loadable({
  loader: () => import('../containers/OnlineApply'),
  loading: MasterLoadingComponent,
});

const AsyncCCF = Loadable({
  loader: () => import('../containers/CCFeature'),
  loading: MasterLoadingComponent,
});

const AsyncBestCC = Loadable({
  loader: () => import('../containers/CCList'),
  loading: MasterLoadingComponent,
});

export class MasterRouter extends Component {
  render() {
    const { enabled, match } = this.props;
    return (
      <Switch>
        <PublicRoute path='/terms' component={<AsynscTermsCondition />} />
        <PublicRoute
          path='/fixed-deposit/ipdc-fd-feature'
          component={<AsyncFeaturePage />}
        />
        {/* <PublicRoute path='/fixed-deposit/online-apply' component={<AsyncOnlineApply />} /> */}
        <PublicRoute
          path='/fixed-deposit/online-apply'
          component={<AsyncOnlineApply />}
        />{' '}
        <PublicRoute
          path='/ipdc-application-details'
          component={<AsyncFdApplicationDetails />}
        />
        <PublicRoute path='/bcc' component={<AsyncBestCC />} />
        <PublicRoute path='/ccf' component={<AsyncCCF />} />
        <PublicRoute
          path='/privacy-policy'
          component={<AsynscPrivacyPolicy />}
        />
        <PublicRoute path='/contact-us' component={<AsynscContact />} />
        <PublicRoute path='/disclaimer' component={<AsynscDisclaimer />} />
        <PublicRoute path='/dps' component={<AsynscDps />} />
        <PublicRoute path='/sign-in' component={<AsynscSignIn />} />
        <PublicRoute path='/sign-up' component={<AsynscSignUp />} />
        <PublicRoute path='/track-application' component={<AsynscTrack />} />
        <PublicRoute path='/partner' component={<AsynscPartner />} />
        <PublicRoute
          path='/advertisement/:productid'
          component={<AsynscAdvertisement />}
        />
        <PublicRoute
          path='/home-loan-calculator'
          component={<AsynscHomeLoanEMI />}
        />
        <PublicRoute path='/blog-details' component={<AsynscSingleNews />} />
        <PublicRoute
          path='/car-loan-calculator'
          component={<AsynscHomeLoanEMI />}
        />
        <PublicRoute
          path='/personal-loan-calculator'
          component={<AsynscHomeLoanEMI />}
        />
        <PublicRoute
          path='/profile-details'
          component={<AsynscProfileDetails />}
        />
        <PublicRoute
          path='/application-history'
          component={<AsynscApplicationHistory />}
        />
        <PublicRoute
          path='/active-application'
          component={<AsynscActiveApplication />}
        />
        <PublicRoute
          path='/track-application-details'
          component={<AsynscTrackApplicationDetails />}
        />
        <PublicRoute path='/no-data-row' component={<AsynscNodata />} />
        <PublicRoute path='/my_matches' component={<AsynscMymatches />} />
        <PublicRoute
          path='/credit-card-compare'
          component={<AsyncCreditCardCompare />}
        />
        <PublicRoute
          path='/credit-card-details'
          component={<AsyncCreditCardDetails />}
        />
        <PublicRoute
          path='/credit-card-eligibility'
          component={<AsyncCreditCardEligibility />}
        />
        <PublicRoute path='/personal-loan' component={<AsyncPersonalLoan />} />
        <PublicRoute path='/home-loan' component={<AsyncHomeLoan />} />
        <PublicRoute path='/car-loan' component={<AsyncCarLoan />} />
        <PublicRoute path='/sme-loan' component={<AsyncPersonalLoan />} />
        <PublicRoute
          path='/application_details'
          component={<AsyncEligibilitySuccess />}
        />
        <PublicRoute path='/news' component={<AsyncnewsPage />} />
        <PublicRoute path='/about' component={<AsyncAbout />} />
        <PublicRoute path='/card_options' component={<AsynscbSorry1 />} />
        <PublicRoute path='/triple-money' component={<AsynscTripleMoney />} />
        <PublicRoute path='/double-money' component={<AsynscDoubleMoney />} />
        <PublicRoute path='/fixed-deposit' component={<AsynscFixedDeposit />} />
        <PublicRoute
          path='/complains-guide'
          component={<AsynscComplainsGuide />}
        />
        <PublicRoute
          path='/monthly-benefit'
          component={<AsynscMonthlyBenifit />}
        />
        <PublicRoute
          path='/saving-account'
          component={<AsynscSavingAccount />}
        />
        <PublicRoute
          path='/personal-loan-eligibility'
          component={<AsyncPersonalEligibility />}
        />
        <PublicRoute
          path='/home-loan-eligibility'
          component={<AsyncHomeEligibility />}
        />
        <PublicRoute
          path='/car-loan-eligibility'
          component={<AsyncCarEligibility />}
        />
        <PublicRoute path='/choose-tenure' component={<AsynscChooseTenure />} />
        <PublicRoute path='/loan-details' component={<AsynscPersonalLoan />} />
        <PublicRoute
          path='/password_reset_confirm'
          component={<AsynscPasswordReset />}
        />
        <PublicRoute
          path='/account_settings'
          component={<AsynscAccountSettings />}
        />
        <PublicRoute path='/investors' component={<AsynscAccountInvestors />} />
        <PublicRoute
          path='/required_documents'
          component={<AsynscAccountRequiredDocument />}
        />
        <PublicRoute path='/apply_now' component={<AsynscAccountApplyNow />} />
        <PublicRoute path='/offer' component={<AsynscNodataOffer />} />
        <PublicRoute
          path='/credit-card-initial'
          component={<AsynscAccountCreditCardCompareInitial />}
        />
        {/* <PublicRoute path='/advertisement' component={<AsynscAdvertisement/>}/> */}
        <Route
          render={() => <AsyncHome />}
          onUpdate={() => window.scrollTo(0, 0)}
        />
      </Switch>
    );
  }
}
