// Import external components refrence
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { ConnectedRouter } from 'react-router-redux';
import Master from './containers/master';
import 'reflect-metadata';
import 'typeface-roboto';
import './styles/app.css';
import './assets/css/animate.min.css';
import './assets/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/VPyEor.css';
import './mobileassets/css/style.css';
import './mobileassets/css/icofont.min.css';
import './mobileassets/css/advertisement1.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

const supportsHistory = 'pushState' in window.history;

ReactDOM.render(
  <Provider store={configureStore.store}>
    <ConnectedRouter history={configureStore.history}>
      {/*<MuiThemeProvider theme={theme}>*/}
      <Master />
      {/*</MuiThemeProvider>*/}
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);
