// - Import react components
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { MasterRouter } from '../../routes/MasterRouter';

export class MasterComponent extends Component {
  static isPrivate = true;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      authed: false,
      dataLoaded: false,
      isVerified: false,
    };
  }

  render() {
    // const {progress, global, loaded, guest, uid, sendFeedbackStatus, hideMessage} = this.props
    const { loading } = this.state;

    if (isMobile) {
      return (
        <div id='master-mobile'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Aamartaka</title>
            <link
              rel='icon'
              type='image/png'
              href='./images/scb/favicon-32x32.png'
              sizes='32x32'
            />
          </Helmet>
          <MasterRouter enabled={!loading} />
        </div>
      );
    }
    
    return (
      <div id='master'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Aamartaka</title>
          <link
            rel='icon'
            type='image/png'
            href='./images/scb/favicon-32x32.png'
            sizes='32x32'
          />
        </Helmet>
        <MasterRouter enabled={!loading} />
      </div>
    );
  }
}

// - Connect commponent to redux store
export default MasterComponent;
